<div class="container mt-3">
    <div class="row justify-content-center">
        <div class="col-24 col-lg-18 col-xl-16">
            <div class="container bg-white mt-2 item-edit">
                <div>
                    <h2 class="news__title">Betaalverzoek {{paymentRequest?.id ? 'wijzigen' : 'toevoegen'}}</h2>
                </div>
                <div *ngIf="form">
                    <form [formGroup]="form">
                        <div class="row" *ngIf="defaultPayments?.length">
                            <mat-form-field class="col-auto flex-grow-1">
                                <mat-label>Standaardbetaling</mat-label>
                                <mat-select [formControl]="fc.payment_default">
                                    <mat-option value="no">
                                        Geen standaardbetaling
                                    </mat-option>
                                    <mat-option *ngFor="let defaultPayment of defaultPayments" [value]="defaultPayment.id">
                                        {{defaultPayment.name}} {{defaultPayment.price | currency : 'EUR'}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-auto flex-grow-1">
                                <mat-label>Titel</mat-label>
                                <input [formControl]="fc.name" matInput type="text">
                                <mat-error [control]="fc.name"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-auto flex-grow-1">
                                <mat-label>Prijs</mat-label>
                                <input [formControl]="fc.price"
                                       appDecimalInput
                                       [allowNegative]="false"
                                       decimalInput="2"
                                       matInput
                                       type="text">
                                <mat-error [control]="fc.price"></mat-error>
                            </mat-form-field>
                            <div class="col-auto align-items-center d-flex">
                                <mat-checkbox [formControl]="fc.price_lower" class="mr-2">
                                    Mag lager zijn
                                </mat-checkbox>
                                <mat-checkbox [formControl]="fc.price_higher">
                                    Mag hoger zijn
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <mat-form-field (click)="picker.open()" class="col-auto flex-grow-1">
                                <mat-label>Zichtbaar vanaf</mat-label>
                                <input [max]="paymentRequest.pay_before" [matDatepicker]="picker" [formControl]="fc.schedule_date" matInput>
                                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error [control]="fc.schedule_date"></mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-auto">
                                <mat-label>Zichtbaar vanaf tijd</mat-label>
                                <input [formControl]="fc.schedule_date_time" matInput placeholder="hh:mm" type="text">
                                <mat-error [control]="fc.schedule_date_time"></mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field (click)="pickerS.open()" class="col-auto flex-grow-1">
                                <mat-label>Betaald voor</mat-label>
                                <input [min]="paymentRequest.pay_before || fc.schedule_date.value" [matDatepicker]="pickerS" [formControl]="fc.pay_before" matInput>
                                <mat-datepicker-toggle [for]="pickerS" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #pickerS></mat-datepicker>
                                <mat-error [control]="fc.pay_before"></mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-auto">
                                <mat-label>Betaald voor tijd</mat-label>
                                <input [formControl]="fc.pay_before_time" matInput placeholder="hh:mm" type="text">
                                <mat-error [control]="fc.pay_before_time"></mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!mainSchool">
                            <div class="pb-2">
                                <div class="font-weight-bold pb-2">
                                    Verzoek voor:
                                </div>
                                <mat-radio-group [formControl]="fc.individual"
                                                 [disabled]="paymentRequest?.id">
                                    <mat-radio-button class="mr-2" value="classrooms">Groepen</mat-radio-button>
                                    <mat-radio-button class="mr-2" value="students">Leerlingen</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <ng-container *ngIf="fc.individual.value === 'students'">
                                <ng-select [items]="students"
                                           [multiple]="true"
                                           [closeOnSelect]="false"
                                           notFoundText="Geen leerlingen gevonden"
                                           bindLabel="name"
                                           bindValue="id"
                                           [virtualScroll]="true"
                                           [formControl]="fc.for_students"
                                           placeholder="Leerling">
                                    <ng-template let-item="item" ng-label-tmp>
                                        {{item.name}}
                                    </ng-template>
                                    <ng-template let-item="item" ng-option-tmp>
                                        {{item.name}} (groep {{item.classroom_name}})
                                    </ng-template>
                                </ng-select>
                            </ng-container>
                            <ng-container *ngIf="fc.individual.value === 'classrooms'">
                                <b>Voor groepen: </b>
                                <mat-selection-list *ngIf="classrooms" class="classroom-list" [formControl]="fc.classrooms">
                                    <mat-list-option [disabled]="initSelectedClassrooms?.length === 1 && initSelectedClassrooms[0] === null && paymentRequest.id"
                                                     *ngIf="AuthorisationService.hasFeature('publicArticles')"
                                                     [value]="null"
                                                     checkboxPosition="before">
                                        Schoolbreed
                                    </mat-list-option>
                                    <mat-list-option
                                        [disabled]="(initSelectedClassrooms?.length === 1 && initSelectedClassrooms[0] === null && paymentRequest.id)
                        || (initSelectedClassrooms | byValue : 'group_' + classroomGroup)"
                                        *ngFor="let classroomGroup of classroomGroups"
                                        [value]="'group_' + classroomGroup"
                                        checkboxPosition="before">
                                        {{classroomGroup}}
                                    </mat-list-option>
                                    <mat-list-option [disabled]="(initSelectedClassrooms?.length === 1 && initSelectedClassrooms[0] === null && paymentRequest.id)
                    || (initSelectedClassrooms | byValue : classroom.id )"
                                                     *ngFor="let classroom of classrooms" [value]="classroom.id"
                                                     checkboxPosition="before">
                                        {{classroom.classroom_name}}
                                    </mat-list-option>
                                </mat-selection-list>
                                <mat-error *ngIf="fc.classrooms.touched" [control]="fc.classrooms"></mat-error>
                            </ng-container>
                        </div>
                    </form>
                </div>
                <div class="floating-buttons" *ngIf="this.form">
                    <button (click)="save()" [disabled]="saving || this.form.disabled" color="primary" mat-fab>
                        <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
                        <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
                    </button>
                </div>
            </div>
            <div class="container bg-white mt-2 item-edit" *ngIf="transactions">
                <div>
                    <h2 class="news__title">Betalingen</h2>
                </div>
                <div class="transactions">
                    <div class="transaction" *ngFor="let transaction of transactions">
                        <div class="icon">
                            <mat-icon mat-list-icon>euro</mat-icon>
                        </div>
                        <div class="info">
                            <div>
                                Betaling voor <b class="curpoint" routerLink="/{{Routenames.parnassysStudent}}/{{transaction.payed_for?.id}}">{{transaction.payed_for?.name}}</b>
                                door <b class="curpoint" routerLink="/{{Routenames.parnassys}}/{{Routenames.users}}/{{transaction.payed_by?.id}}">{{transaction.payed_by?.name}}</b>
                            </div>
                            <div class="date">{{transaction.updated_at | date}}</div>
                        </div>
                        <div class="amount">
                            <i class="fas fa-coins mr-3"
                               matTooltip="Contant betaald"
                               *ngIf="transaction.cash"></i>
                            <span [class.add]="transaction.amount > 0">
                            {{-transaction.amount | currency : 'EUR'}}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container bg-white mt-2 item-edit" *ngIf="notPaidStudents">
                <div>
                    <h2 class="news__title">Nog niet betaald</h2>
                </div>
                <div class="transactions">
                    <div class="transaction" *ngFor="let student of notPaidStudents">
                        <div class="icon">
                            <mat-icon mat-list-icon>face</mat-icon>
                        </div>
                        <div class="info">
                            <div>
                                <b class="curpoint" routerLink="/{{Routenames.parnassysStudent}}/{{student?.id}}">{{student?.name}}</b>
                            </div>
                            <div class="date">
                                Groep:
                                <ng-container *ngFor="let classroom of student.classrooms; let last = last">
                                    {{classroom.classroom_name}}{{last ? '' : ', '}}
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
