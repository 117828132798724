import {Classroom} from './classroom.class';
import {Student} from './student.class';
import {Transaction} from './transaction';
import {PaymentDefault} from './payment-default';

export class PaymentRequest {
    id: number;
    name: string;
    price: number;
    priceToPay: number;
    price_higher = false;
    price_lower = false;
    school_id: number;
    classrooms: Classroom[] = [];
    payment_default: PaymentDefault;
    created_by: number;
    pay_before: Date;
    updated_at: Date;
    created_at: Date;
    deleted_at: Date;
    groupedClassrooms: string[];
    transactions: Transaction[];
    readonly students: Student[];
    for_students: Student[];
    schedule_date: Date;
}
