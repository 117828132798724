import {Component, OnInit} from '@angular/core';
import {PaymentService} from '../services/payment.service';
import {PaymentRequest} from '../classes/payment-request';
import {SortingComponent} from '../sorting.component';
import {Router} from '@angular/router';
import {Routenames} from '../route-names.enum';
import {LocalStorage} from '../storage.class';
import {startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {combineLatest} from 'rxjs';

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent extends SortingComponent<PaymentRequest> implements OnInit {

    fcTrashed = new FormControl(false);

    paymentRequests: PaymentRequest[];

    constructor(private paymentService: PaymentService,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.fcTrashed.valueChanges.pipe(startWith(false))]).subscribe(([school, trashed]) => {
            this.subscriptions.add(this.paymentService.getPaymentRequests(trashed).subscribe(requests => {
                this.list = requests.data;
            }));
        }));
    }

    editPayment(id?: number) {
        this.router.navigate([Routenames.payments, Routenames.editPart, id ? id : Routenames.newPart]);
    }

    delete(paymentRequest: PaymentRequest) {
        this.subscriptions.add(this.paymentService.deletePaymentRequest(paymentRequest.id).subscribe(() => {
            this.list = this.list.filter(p => p.id !== paymentRequest.id);
        }));
    }

}
